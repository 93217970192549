import i18n from '../../i18n';

export const rouletteFields = [
  {
    key: 'identifierNumber',
    sortable: true,
    label: i18n.t('transactions.roundID').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'roundId',
    sortable: true,
    label: i18n.t('transactions.roundCode').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'client',
    sortable: true,
    label: i18n.t('tableReports.client').toUpperCase(),
  },
  {
    key: 'operator',
    sortable: true,
    label: i18n.t('tableReports.operator').toUpperCase(),
  },
  {
    key: 'playerId',
    sortable: true,
    label: i18n.t('tableReports.userId').toUpperCase(),
    class: 'space-nowrap text-center',
  },
  {
    key: 'player',
    sortable: true,
    label: i18n.t('betDetail.user').toUpperCase(),
  },
  {
    key: 'roulette',
    sortable: true,
    label: i18n.t('tableReports.casinoName').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'currency',
    sortable: true,
    label: i18n.t('tableReports.currency').toUpperCase(),
  },
  {
    key: 'transactionId',
    sortable: true,
    label: i18n.t('tableReports.transactionId').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'betId',
    sortable: true,
    label: 'BET ID',
    class: 'space-nowrap',
  },
  {
    key: 'details',
    sortable: true,
    label: i18n.t('tableReports.details').toUpperCase(),
  },
  {
    key: 'WL',
    sortable: true,
    label: 'WL',
    class: 'space-nowrap',
  },
];

export const globalFields = [
  {
    key: 'type',
    sortable: true,
    label: i18n.t('tableReports.transactionType').toUpperCase(),
    class: 'space-nowrap text-center',
  },
  {
    key: 'debit',
    sortable: true,
    label: i18n.t('tableReports.debit').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'credit',
    sortable: true,
    label: i18n.t('tableReports.credit').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'userBalance',
    sortable: true,
    label: i18n.t('tableReports.balance').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'errorRound',
    sortable: true,
    label: i18n.t('tableReports.error').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'rollbackRound',
    sortable: true,
    label: i18n.t('tableReports.rollback').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'retryRound',
    sortable: true,
    label: i18n.t('tableReports.retry').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'createdAt',
    sortable: true,
    label: i18n.t('tableReports.date').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'playerIp',
    sortable: true,
    label: 'IP'.toUpperCase(),
  },
  {
    key: 'platform',
    sortable: true,
    label: i18n.t('tableReports.deviceType').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'playerCountry',
    sortable: true,
    label: i18n.t('tableReports.country').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'userAgent',
    sortable: true,
    label: 'User Agent'.toUpperCase(),
    class: 'space-nowrap',
  },
];

export const externalGamesFields = [
  {
    key: 'round',
    sortable: true,
    label: i18n.t('transactions.roundCode').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'player.userId',
    sortable: true,
    label: i18n.t('tableReports.userId').toUpperCase(),
    class: 'space-nowrap text-center',
  },
  {
    key: 'player.username',
    sortable: true,
    label: i18n.t('betDetail.user').toUpperCase(),
  },
  {
    key: 'client.name',
    sortable: true,
    label: i18n.t('tableReports.client').toUpperCase(),
  },
  {
    key: 'operator.name',
    sortable: true,
    label: i18n.t('tableReports.operator').toUpperCase(),
  },
  {
    key: 'player.WL',
    sortable: true,
    label: 'wl'.toUpperCase(),
  },
  {
    key: 'game',
    sortable: true,
    label: i18n.t('tableReports.casinoName').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'currency.short',
    sortable: true,
    label: i18n.t('tableReports.currency').toUpperCase(),
    class: 'space-nowrap',
  },
];

export const dragonTigerTransactionsFields = [
  {
    key: 'round.identifierNumber',
    sortable: true,
    label: 'ID'.toUpperCase(),
  },
  {
    key: 'client.name',
    sortable: true,
    label: i18n.t('tableReports.client').toUpperCase(),
  },
  {
    key: 'player.operator.name',
    sortable: true,
    label: i18n.t('tableReports.operator').toUpperCase(),
  },
  {
    key: 'player.userId',
    sortable: true,
    label: i18n.t('tableReports.userId').toUpperCase(),
    class: 'space-nowrap text-center',
  },
  {
    key: 'player.username',
    sortable: true,
    label: i18n.t('betDetail.user').toUpperCase(),
  },
  {
    key: 'game.name',
    sortable: true,
    label: i18n.t('tableReports.casinoName').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'currency.isoCode',
    sortable: true,
    label: i18n.t('tableReports.currency').toUpperCase(),
  },
  {
    key: 'uuid',
    sortable: true,
    label: i18n.t('tableReports.transactionId').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'details',
    sortable: true,
    label: i18n.t('tableReports.details').toUpperCase(),
  },
  {
    key: 'player.WL',
    sortable: true,
    label: 'WL',
    class: 'space-nowrap',
  },
  {
    key: 'type',
    sortable: true,
    label: i18n.t('tableReports.transactionType').toUpperCase(),
    class: 'space-nowrap text-center',
  },
  {
    key: 'debit',
    sortable: true,
    label: i18n.t('tableReports.debit').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'credit',
    sortable: true,
    label: i18n.t('tableReports.credit').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'userBalance',
    sortable: true,
    label: i18n.t('tableReports.balance').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'createdAt',
    sortable: true,
    label: i18n.t('tableReports.date').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'playerIp',
    sortable: true,
    label: 'IP'.toUpperCase(),
  },
  {
    key: 'platform',
    sortable: true,
    label: i18n.t('tableReports.deviceType').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'playerCountry',
    sortable: true,
    label: i18n.t('tableReports.country').toUpperCase(),
    class: 'space-nowrap',
  },
];

export const wheelsTransactionsFields = [
  {
    key: 'round.identifierNumber',
    sortable: true,
    label: 'ID'.toUpperCase(),
  },
  {
    key: 'client',
    sortable: true,
    label: i18n.t('tableReports.client').toUpperCase(),
  },
  {
    key: 'player.operator.name',
    sortable: true,
    label: i18n.t('tableReports.operator').toUpperCase(),
  },
  {
    key: 'player.userId',
    sortable: true,
    label: i18n.t('tableReports.userId').toUpperCase(),
    class: 'space-nowrap text-center',
  },
  {
    key: 'player.username',
    sortable: true,
    label: i18n.t('betDetail.user').toUpperCase(),
  },
  {
    key: 'game.name',
    sortable: true,
    label: i18n.t('tableReports.casinoName').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'currency.isoCode',
    sortable: true,
    label: i18n.t('tableReports.currency').toUpperCase(),
  },
  {
    key: 'bet.uuid',
    sortable: true,
    label: i18n.t('tableReports.transactionId').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'details',
    sortable: true,
    label: i18n.t('tableReports.details').toUpperCase(),
  },
  {
    key: 'player.WL',
    sortable: true,
    label: 'WL',
    class: 'space-nowrap',
  },
  {
    key: 'type',
    sortable: true,
    label: i18n.t('tableReports.transactionType').toUpperCase(),
    class: 'space-nowrap text-center',
  },
  {
    key: 'debit',
    sortable: true,
    label: i18n.t('tableReports.debit').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'credit',
    sortable: true,
    label: i18n.t('tableReports.credit').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'userBalance',
    sortable: true,
    label: i18n.t('tableReports.balance').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'createdAt',
    sortable: true,
    label: i18n.t('tableReports.date').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'playerIp',
    sortable: true,
    label: 'IP'.toUpperCase(),
  },
  {
    key: 'platform',
    sortable: true,
    label: i18n.t('tableReports.deviceType').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'playerCountry',
    sortable: true,
    label: i18n.t('tableReports.country').toUpperCase(),
    class: 'space-nowrap',
  },
];

export const blackJackTransactionsFields = [
  {
    key: 'round.identifyNumber',
    sortable: true,
    label: 'ID'.toUpperCase(),
  },
  {
    key: 'client',
    sortable: true,
    label: i18n.t('tableReports.client').toUpperCase(),
  },
  {
    key: 'operator.name',
    sortable: true,
    label: i18n.t('tableReports.operator').toUpperCase(),
  },
  {
    key: 'player.playerId',
    sortable: true,
    label: i18n.t('tableReports.userId').toUpperCase(),
    class: 'space-nowrap text-center',
  },
  {
    key: 'player.username',
    sortable: true,
    label: i18n.t('betDetail.user').toUpperCase(),
  },
  {
    key: 'game.name',
    sortable: true,
    label: i18n.t('tableReports.casinoName').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'currency.isoCode',
    sortable: true,
    label: i18n.t('tableReports.currency').toUpperCase(),
  },
  {
    key: 'uuid',
    sortable: true,
    label: i18n.t('tableReports.transactionId').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'details',
    sortable: true,
    label: i18n.t('tableReports.details').toUpperCase(),
  },
  {
    key: 'player.WL',
    sortable: true,
    label: 'WL',
    class: 'space-nowrap',
  },
  {
    key: 'type',
    sortable: true,
    label: i18n.t('tableReports.transactionType').toUpperCase(),
    class: 'space-nowrap text-center',
  },
  {
    key: 'debit',
    sortable: true,
    label: i18n.t('tableReports.debit').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'credit',
    sortable: true,
    label: i18n.t('tableReports.credit').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'userBalance',
    sortable: true,
    label: i18n.t('tableReports.balance').toUpperCase(),
    class: 'text-end',
  },
  {
    key: 'createdAt',
    sortable: true,
    label: i18n.t('tableReports.date').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'playerIp',
    sortable: true,
    label: 'IP'.toUpperCase(),
  },
  {
    key: 'platform',
    sortable: true,
    label: i18n.t('tableReports.deviceType').toUpperCase(),
    class: 'space-nowrap',
  },
  {
    key: 'playerCountry',
    sortable: true,
    label: i18n.t('tableReports.country').toUpperCase(),
    class: 'space-nowrap',
  },
];

export const wheelBetDetail = [
  {
    key: 'number',
    sortable: false,
    label: 'numero'.toUpperCase(),
  },
  {
    key: 'amount',
    sortable: true,
    label: 'monto'.toUpperCase(),
  },
];

export const wheelBetDetailEarnings = [
  {
    key: 'earnings',
    sortable: true,
    label: 'ganancias'.toUpperCase(),
    formatter: (item) => {
      return item || 0;
    },
  },
];

export const wheelBetDetailJackpot = [
  {
    key: 'jackpot',
    sortable: true,
    label: 'jackpot'.toUpperCase(),
    formatter: (item) => (item ? `X${item}` : 0),
  },
  {
    key: 'earningsJackpot',
    sortable: true,
    label: 'ganancias con jackpot'.toUpperCase(),
    formatter: (item) => item || 0,
  },
];
