import Swal from 'sweetalert2';
import { clientPass, onlyAdmins, operatorPass } from './roles';

export const clientPassGuard = (to, from, next) => {
  let user = JSON.parse(localStorage.getItem('user'));
  const role = user.user.role.name;

  if (
    (clientPass.includes(role) && !onlyAdmins.includes(role)) ||
    operatorPass.includes(role)
  ) {
    Swal.fire('Error', 'Ops. Acceso solo para administradores.', 'error');
    return next({ name: 'home' });
  }

  next();
};
