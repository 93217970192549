import { routes } from '../../helpers/axios/apiRoutes';
import axiosInsDragonTiger from '../../helpers/axios/dragonTiger';

export const state = {
  operatorGames: [],
};

export const getters = {
  getOperatorGames() {
    return state.operatorGames;
  },
};

export const mutations = {
  setOperatorGames(state, payload) {
    state.operatorGames = payload;
  },
};

export const actions = {
  async fetchOperatorGames({ commit }, { operatorId }) {
    const routesGames = routes.admin_api.games.path;

    const route = `${routesGames}/operator/${operatorId}`;

    try {
      const { data } = await axiosInsDragonTiger.get(route);

      if (data.error) return commit('setOperatorGames', []);

      commit('setOperatorGames', data.games);
    } catch (error) {
      console.log('ERROR FETCHING OPERATOR GAMES', error);
    }
  },
};
