import { axiosIns, axiosInsWheel } from '../../helpers/axios/index';
import { requestSuccess } from '../helpers/requestSuccess';
import store from '../store';

export const state = {
  players: [],
  success: false,
};
export const getters = {
  getPlayers() {
    return state.players;
  },
  getSuccess() {
    return state.success;
  },
};
export const mutations = {
  setPlayers(state, payload) {
    state.players = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
};
export const actions = {
  async fetchPlayerRound({ commit }, { options }) {
    try {
      const { data, status } = await axiosIns.get('/player-round/getPlayer', {
        ...options,
      });

      if (status > 200) {
        commit('setSuccess', false);
        return commit('setPlayers', []);
      }
      if (data.error) {
        commit('setSuccess', false);
        return commit('setPlayers', []);
      }

      commit('setSuccess', true);
      commit('setPlayers', data.res);
    } catch (error) {
      console.log('ERROR FETCH PLAYER ROUND', error);
    }
  },
  async changePlayerAdmin({ commit }, { player, isAdmin }) {
    try {
      const rouletteApi = await axiosIns.put(
        `player-round/${player._id}/player-admin`,
        { isAdmin }
      );
      const adminApi = await axiosInsWheel.put(
        `players/${player.tokenWallet}/player-admin`,
        { isAdmin }
      );

      const data = await Promise.allSettled([rouletteApi, adminApi]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR changePlayerAdmin', error);
      commit('setSuccess', false);
    }
  },
  async changePlayerPhysic({ commit }, { player, isPhysic, board, type }) {
    try {
      const rouletteApi = await axiosIns.put(
        `player-round/${player._id}/player-physic`,
        { isPhysic, board }
      );

      const wheelApi = await axiosInsWheel.put(
        `players/${player.tokenWallet}`,
        { isPhysic, board, type }
      );

      const data = await Promise.allSettled([rouletteApi, wheelApi]);

      const { success, errors } = requestSuccess(...data);

      if (!success) return commit('setSuccess', false);

      commit('setSuccess', true);
      store.commit('notification/setErrors', errors);
    } catch (error) {
      console.log('ERROR changePlayerPhysic', error);
      commit('setSuccess', false);
    }
  },
  async closeWheelSession({ commit }, { player, gameUuid }) {
    const { userId } = player;

    try {
      const { status } = await axiosInsWheel.delete(
        `players/${userId}/remove-session/${gameUuid}`
      );

      if (status >= 400) throw new Error('ERROR REMOVIN PLAYER');

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR CLOSE WHEEL SESSION', error);
      commit('setSuccess', false);
    }
  },
};
