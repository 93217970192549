export const formatAllRounds = (values, limit) => {
  const rounds = [];

  for (let data of values) {
    const { value } = data;
    if (!value) continue;
    rounds.push(...value);
  }

  const sortedRounds = rounds.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  return sortedRounds
    .map((r) => {
      return {
        //   ...transaction,
        _id: r._id,
        uuid: r.uuid,
        globalResult: r.result,
        start_date: r.start_date,
        end_date: r.end_date,
        identifierNumber: r.identifierNumber,
        status: r.open,
        JACKPOT: r.jackpot_values || r.jackpot,
        gameName: r.code ? r.code.split('-')[0] : r.game.name,
      };
    })
    .slice(0, limit);
};
