import { axiosIns } from '../../helpers/axios/index';

export const state = {
  chips: [],
  success: false,
};

export const getters = {
  isSuccess() {
    return state.success;
  },
  getChips() {
    return state.chips;
  },
  nFormatter: () => (num, digits) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
    ];

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

    const item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });

    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : String(num).replace(rx, '$1');
  },
};

export const mutations = {
  setChips(state, payload) {
    state.chips = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
};

export const actions = {
  async fetchChipsDefault({ commit }) {
    try {
      const { data } = await axiosIns.get('/chips');

      if (!data.ok) {
        commit('setChips', []);
        commit('setSuccess', false);
        return false;
      }

      commit('setSuccess', true);
      commit('setChips', data.chips);
      return data.chips;
    } catch (error) {
      console.log('ERROR FETCH CHIPS', error);
      commit('setSuccess', false);
      commit('setChips', []);
    }
  },
  async updateChip({ commit }, { path, chip, form }) {
    try {
      const { data } = await axiosIns.put(`${path}/${chip}/update-chips`, {
        ...form,
      });

      if (!data.ok) return data;

      commit('setChips', []);
      return data;
    } catch (error) {
      console.log('ERROR UPDATE CHIP', error);
      return false;
    }
  },
};
