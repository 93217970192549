import { axiosIns } from '../../helpers/axios/index';

export const state = {
  roles: [],
};

export const getters = {
  getRoles() {
    return state.roles;
  },
};

export const mutations = {
  setRoles(state, payload) {
    state.roles = [];
    state.roles = payload;
  },
};

export const actions = {
  async fetchRoles({ commit }) {
    try {
      const { data } = await axiosIns.get('/roles');
      commit('setRoles', data);
    } catch (error) {
      console.log('ERROR FETCH ROLES', error);
      commit('setRoles', []);
    }
  },
};
