export const CHANGE_PHASE_EVENT = 'change-phase';
export const BET = 'bet';

export const phases = {
  bet_time: 'bet_time',
  processing_bet: 'processing_bet',
  processing_jackpot: 'processing_jackpot',
  wait_result: 'wait_result',
  processing_next_round: 'processing_next_round',
};
