import Swal from 'sweetalert2';
import { onlyAdmins } from './roles';

export const roleGuard = (to, from, next) => {
  let user = JSON.parse(localStorage.getItem('user'));
  const role = user.user.role.name;
  if (!onlyAdmins.includes(role)) {
    Swal.fire(
      'Error',
      'Ops, no tienes permiso para ingresar aqui, habla con el administrador',
      'error'
    );
    return next({ name: 'home' });
  }
  next();
};
