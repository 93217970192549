import { axiosIns } from '../../helpers/axios/index';

export const state = {
  apis: [],
};

export const getters = {
  getApis() {
    return state.apis;
  },
};

export const mutations = {
  setApis(state, payload) {
    state.apis = payload;
  },
};

export const actions = {
  async fetchExchangeApis({ commit }) {
    try {
      const { data, status } = await axiosIns.get('/exchage-api');
      if (status > 200) return commit('setApis', []);

      const availableApis = data.apis.filter((api) => api.status === true);
      const formattedApis = availableApis.map((api) => {
        return {
          text: api.name,
          value: api._id,
        };
      });
      commit('setApis', formattedApis);
    } catch (error) {
      console.log('ERROR FETCH EXCHANGE APIS', error);
    }
  },
};
