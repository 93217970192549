export const formattBets = (betsFetch) => {
  const betItems = [];
  for (let bet in betsFetch) {
    if (betsFetch[bet].length) {
      if (bet === 'calleNumbers') {
        const calleNumbers = [];

        for (let item = 0; item < betsFetch[bet].length; item += 3) {
          calleNumbers.push({
            betType: bet,
            amount: betsFetch[bet][item].amount,
            number: [
              betsFetch[bet][item].number,
              betsFetch[bet][item].number + 1,
              betsFetch[bet][item].number + 2,
            ],
          });
        }

        betItems.push(...calleNumbers);
      }

      if (bet === 'specialCalle') {
        const specialCalle = [];

        for (let item = 0; item < betsFetch[bet].length; item += 3) {
          specialCalle.push({
            betType: bet,
            amount: betsFetch[bet][item].amount,
            type: '37-0-1-2-3',
          });
        }

        betItems.push(...specialCalle);
      }
      if (bet === 'cuadroNumbers') {
        const cuadroNumbers = [];

        for (let item = 0; item < betsFetch[bet].length; item += 4) {
          cuadroNumbers.push({
            betType: bet,
            amount: betsFetch[bet][item].amount,
            number: [
              betsFetch[bet][item].number,
              betsFetch[bet][item].number + 1,
              betsFetch[bet][item].number + 3,
              betsFetch[bet][item].number + 4,
            ],
          });
        }

        betItems.push(...cuadroNumbers);
      }

      if (bet === 'lineaNumbers') {
        const lineaNumbers = [];

        for (let item = 0; item < betsFetch[bet].length; item += 6) {
          if (betsFetch[bet][item].number === 0) {
            lineaNumbers.push({
              betType: bet,
              amount: betsFetch[bet][item].amount,
              number: [
                betsFetch[bet][item].number,
                betsFetch[bet][item].number + 1,
                betsFetch[bet][item].number + 2,
                betsFetch[bet][item].number + 3,
              ],
            });
          } else {
            lineaNumbers.push({
              betType: bet,
              amount: betsFetch[bet][item].amount,
              number: [
                betsFetch[bet][item].number,
                betsFetch[bet][item].number + 1,
                betsFetch[bet][item].number + 2,
                betsFetch[bet][item].number + 3,
                betsFetch[bet][item].number + 4,
                betsFetch[bet][item].number + 5,
              ],
            });
          }
        }

        betItems.push(...lineaNumbers);
      }

      if (bet === 'semiPlenoNumbers') {
        const semiPlenoNumbers = [];
        for (let item = 0; item < betsFetch[bet].length; item += 2) {
          const number = betsFetch[bet][item].number;
          const nextNumber = betsFetch[bet][item + 1].number;

          semiPlenoNumbers.push({
            betType: bet,
            amount: betsFetch[bet][item].amount,
            number: [number, nextNumber],
          });
        }

        betItems.push(...semiPlenoNumbers);
      }

      if (bet === 'plenoNumbers') {
        for (let item = 0; item < betsFetch[bet].length; item++) {
          betItems.push({
            betType: bet,
            number: betsFetch[bet][item].number,
            amount: betsFetch[bet][item].amount,
          });
        }
      }

      if (
        [
          'chanceSimple',
          'dozens',
          'columns',
          'color',
          'even_odd',
          'cubre',
        ].includes(bet)
      ) {
        for (let item = 0; item < betsFetch[bet].length; item++) {
          betItems.push({
            betType: bet,
            type: betsFetch[bet][item].type,
            amount: betsFetch[bet][item].amount,
          });
        }
      }
    }
  }

  return betItems;
};

export const getEarnings = (earnings) => {
  return earnings.map((earn) => {
    const winnerType = earn.bet.split('/')[0];
    const winnerNumber = earn.bet.split('/')[1];
    return {
      betType: winnerType,
      number: winnerNumber,
      betAmount: earn.amountOriginal,
      earnings: earn.earning,
    };
  });
};

export const addEarningsToBet = (betItems, earningsBets) => {
  const formatted = betItems;
  betItems.map((bet, index) => {
    if (bet.type) {
      earningsBets.filter((earn) => {
        if (
          earn.betType === bet.betType &&
          earn.number === bet.type &&
          bet.amount === earn.betAmount
        ) {
          formatted[index] = {
            ...formatted[index],
            earnings: earn.earnings,
          };
          return earn.earnings;
        }
      });
    } else {
      earningsBets.filter((earn) => {
        if (earn.betType === bet.betType && bet.amount === earn.betAmount) {
          if (bet.number instanceof Array) {
            if (bet.number.includes(Number(earn.number))) {
              formatted[index] = {
                ...formatted[index],
                earnings: earn.earnings,
              };
            }
          }

          if (typeof bet.number === 'number') {
            if (bet.number === Number(earn.number)) {
              formatted[index] = {
                ...formatted[index],
                earnings: earn.earnings,
              };
            }
          }
          return earn.earnings;
        }
      });
    }
  });

  return formatted;
};

export const addEarningsToBetDragon = (
  earnings = [{ amountOriginal: 0, bet: '', earning: 0 }]
) => {
  const earningsInBet = { type: 'EARNINGS' };

  for (let earn of earnings) {
    const { bet, earning } = earn;
    let key = '';

    if (bet.includes('-')) {
      const betsplitted = bet.split('-');
      key = betsplitted[1];
      earningsInBet[key] = { amount: earning, winner: betsplitted[2] };
    } else {
      key = bet;
      earningsInBet[key] = earning;
    }
  }

  return earningsInBet;
};
