import store from '@/state/store';

export const state = {
  type: null,
  message: null,
  envs: {},
  errors: [],
  loadingPercentage: 0,
};

export const getters = {
  mapEnvs() {
    const newEnvs = {};
    const envs = process.env;
    for (const env in envs) {
      newEnvs[envs[env]] =
        env.split('VUE_APP_').length > 1
          ? env.split('VUE_APP_')[1].split('_').join(' ')
          : env;
    }
    return newEnvs;
  },
  getErrors() {
    return state.errors;
  },
  getLoadingState() {
    return (
      (
        (state.loadingPercentage * 100) /
        store.getters['games/getGames'].length
      ).toFixed(2) + ' %'
    );
  },
};

export const mutations = {
  success(state, message) {
    state.type = 'success';
    state.message = message;
  },
  error(state, message) {
    state.type = 'danger';
    state.message = message;
  },
  clear(state) {
    state.type = null;
    state.message = null;
  },
  errorRepeatingPassword(state, message) {
    (state.type = 'danger'), (state.message = message);
  },
  setErrors(state, payload) {
    state.errors = payload;
  },
  plusPercentage(state) {
    state.loadingPercentage += 1;
  },
  resetPercentage(state) {
    state.loadingPercentage = 0;
  },
};

export const actions = {
  success({ commit }, message) {
    commit('success', message);
  },
  error({ commit }, message) {
    commit('error', message);
  },
  clear({ commit }) {
    commit('clear');
  },
  setErrorRepeatingPassword({ commit }, message) {
    commit('errorRepeatingPassword', message);
  },
};
