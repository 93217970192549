export const routes = {
  admin_api: {
    login: {
      path: '/auth/login',
    },
    clients: {
      path: '/client',
      listAll: '/client',
      games: 'all-games',
    },
    operators: {
      path: '/operator',
      client: 'client',
      listAll: 'all',
      games: 'all-games',
      editGame: 'game',
    },
    games: {
      path: '/game',
    },
  },
  roulette_api: {
    operators: {
      path: 'operators',
    },
    clients: {
      path: 'clients',
      block: 'block',
      disblock: 'disblock',
      roulettes: 'roulettes',
      importRoulettes: 'import-roulettes',
      externalGames: 'external-games',
      deleteRoulette: 'delete-roulette',
    },
    currencies: {
      path: 'currencies',
    },
    launch: {
      path: 'launch',
    },
    roulettes: {
      path: 'roulettes',
    },
    externalGames: {
      path: 'external-games',
    },
    round: {
      path: 'round',
    },
    bets: {
      path: 'bets',
    },
    chisps: {
      path: 'chips',
    },
    players: {
      path: 'player-round',
    },
    users: {
      path: 'users',
    },
    logs: {
      path: 'logs',
    },
    reports: {
      path: 'reports',
      subRoutes: {
        transactions: 'transactions',
        history: 'history',
        jackpots: 'jackpots',
        ggrByGame: 'ggr-by-game',
        ggrByCasino: 'ggr-by-casino',
        players: 'players',
        providerLogs: 'provider-logs',
        faqs: 'faqs',
      },
    },
  },
};
