import {
  axiosInsBlackJack,
  axiosIns as axiosInsRoulette,
} from '../../helpers/axios/index';
import i18n from '../../i18n';

export const state = {
  games: [],
  gamesTable: [
    {
      key: 'name',
      sortable: true,
      label: 'Nombre',
    },
    {
      key: 'logo',
      label: 'Logo',
    },
    {
      key: 'providerId',
      label: 'Provider ID',
    },
    {
      key: 'status',
      label: i18n.t('tableReports.status'),
      formatter: (item) => (item ? 'ACTIVO' : 'INACTIVO'),
    },
    {
      key: 'actions',
      label: 'Acciones',
    },
  ],
  success: false,
  cards: [],
  types: {
    hearts: '♥',
    diamont: '♦',
    clover: '♣',
    pica: '♠',
  },
};

export const getters = {
  getGames(state) {
    return state.games;
  },
  getTableHeaders(state) {
    return state.gamesTable;
  },
  getSuccess(state) {
    return state.success;
  },
  getTypes(state) {
    return state.types;
  },
  getCards(state) {
    const cards = [];
    const types = Object.keys(state.types);
    for (let i = 0; i < 4; i++) {
      for (let j = 1; j <= 13; j++) {
        let name = null;

        switch (j) {
          case 1:
            name = 'A';
            break;
          case 11:
            name = 'J';
            break;
          case 12:
            name = 'Q';
            break;
          case 13:
            name = 'K';
            break;

          default:
            name = j;
            break;
        }

        cards.push({
          type: types[i],
          value: j,
          name,
          color: i < 2 ? 'red' : 'black',
        });
      }
    }

    return cards;
  },
};

export const mutations = {
  setWheels(state, payload) {
    state.games = payload;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
};

export const actions = {
  async fetchGames({ commit }) {
    try {
      const { data, status } = await axiosInsBlackJack.get('/game/all');

      if (status > 200) return commit('setWheels', []);

      commit('setWheels', data.games);
    } catch (error) {
      console.log('ERROR FETCH WHEELS', error);
    }
  },
  async update({ commit }, doc) {
    const { uuid } = doc;

    try {
      const { status } = await axiosInsBlackJack.put(
        `/game/update/${uuid}`,
        doc
      );

      if (status > 201) throw new Error('Error updating black jack');

      commit('setSuccess', true);
    } catch (error) {
      commit('setSuccess', false);
      throw new Error('Error update black jack', error);
    }
  },
  async create({ commit }, doc) {
    try {
      const { status } = await axiosInsBlackJack.post('/game/create', doc);

      if (status > 201) throw new Error('Error black jack');

      commit('setSuccess', true);
    } catch (error) {
      commit('setSuccess', false);
      throw new Error('Error black jack', error);
    }
  },
  async remove({ commit }, uuid) {
    try {
      const { status } = await axiosInsBlackJack.delete(`/game/remove/${uuid}`);

      if (status > 201) throw new Error('Error deleting black jack');

      commit('setSuccess', true);
    } catch (error) {
      commit('setSuccess', false);
      throw new Error('Error upadte black jack', error);
    }
  },
  async addCrupierToGame({ commit }, { gameUuid, crupier }) {
    try {
      const { status } = await axiosInsBlackJack.put(
        `/game/add-croupier/${gameUuid}`,
        {
          croupierUuid: crupier,
        }
      );
      if (status > 201) return commit('setSuccess', false);
      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR addCrupierToGame', error);
    }
  },
  async sendCardCrupier({ commit }, { card, game }) {
    const data = {
      game: 'blackjack',
      table: game.providerId,
      type: card.type,
      cardValue: String(card.value),
    };

    try {
      const { status } = await axiosInsRoulette.put(
        'external-games/blackjack/send-card',
        data
      );

      if (status > 200) return commit('setSuccess', false);

      commit('setSuccess', true);
    } catch (error) {
      console.log('ERROR SEND CARD CRUPIER', error);
      commit('setSuccess', false);
    }
  },
};
