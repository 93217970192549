export const requestSuccess = (...requests) => {
  let success = true;
  const errors = [];

  for (let req of requests) {
    const { value, reason } = req;
    if (reason) {
      errors.push({ reason: reason.message, api: reason.config.baseURL });
      continue;
    }
    const { status } = value;
    if (status > 201) success = false;
  }

  return { success, errors };
};

// IMPLEMENT PROMISE.ALLSETTLED PARA OBTENER LOS ERRORES CUANDO UNA API NO ESTE ACTIVA
