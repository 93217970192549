export const ROULETTE = 'roulettes';
export const WHEEL_FORTUNE = 'wheelFortune';
export const EXTERNAL_GAMES = 'external-games';
export const BLACK_JACK = 'BLACKJACK';
export const BOILER_PLATE_GAMES = 'games';
export const ROULETTE_NAME = 'Roulette';
export const DRAGON_NAME = 'Dragon Tiger';
export const WHEEL_NAME = 'Wheel Fortune';
export const EXTERNAL_GAMES_NAME = 'External Games';
export const BLACK_JACK_NAME = 'Black Jack';
export const ADMIN_ROLE = 'ADMIN';
export const CLIENT_ROLE = 'CLIENT';
export const OPERATOR_ROLE = 'OPERATOR';
export const CRUPIER_ROLE = 'CRUPIER';

export const PlayerTypeVirtual = {
  PLAYER: 'player', // normal player (default)
  AGENT: 'agent', // el agente en taquilla que apuesta por el jugador
};

export const PlayerTypePhysic = {
  BOARD: 'board', // el jugador de mesa (es fisico)
  VIDEO: 'video', // el jugador de video (es fisico)
};
