import moment from 'moment';

const filters = [
  {
    name: 'dateFormat',
    execute: (value) => {
      return moment(value).format('LLL');
    },
  },
];

export default filters;
