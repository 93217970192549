export const GamesTypes = {
  ALL: 'ALL',
  Roulette: 'Roulette',
  DragonTiger: 'Dragon Tiger',
  ExternalGames: 'External Games',
  WheelFortune: 'Wheel Fortune',
  BlackJack: 'Black Jack',
};

export const responseTransactionGameType = {
  roulette: 'Roulette',
  BLACJACK: 'Black Jack',
  WHEEL: 'Wheel Fortune',
  'DRAGON-TIGER': 'Dragon Tiger',
  'external-game': 'External Games',
};
