import store from '../../state/store';
import { axiosIns } from '../axios';

export const setUserAction = (place, from, userDo) => {
  //in, from, acciones: agregar, editar, eliminar
  const { id: userId } = getUserinfo();

  const schema = {
    userId,
    from: from || 'Ingresó al sistema',
    action: userDo || 'Solo navegación',
    place,
    created: new Date().toString(),
  };

  return schema;
};

export const getUserinfo = () => {
  const data = store.state.user.user._id
    ? store.state.user.user
    : JSON.parse(localStorage.getItem('user')).user;

  //!Si existe el state trabajamos con state, si no usamos el LS
  const user = {
    id: data._id,
  };
  return user;
};

export const saveActions = async () => {
  let { actions } = store.state.actions;
  return axiosIns
    .post('actions', actions)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
};
