<template>
  <div class="vw-100 vh-100">
    <div class="d-flex justify-content-center align-items-center vh-100">
      <div class="alert alert-danger">
        <p>SESION EXPIRADA</p>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
  mounted() {
    let logout = setTimeout(() => {
      this.$store.commit('user/logout');
      window.location.replace('/login'); //Recarga la pagina
    }, 20000);

    Swal.fire({
      title: 'Tu sesión va a expirar',
      text: '¿Deseas extender la sesión?',
      icon: 'warning',
      confirmButtonText: 'Sí',
    }).then((result) => {
      if (result.isConfirmed) {
        this.$emit('resetCount'); //Dispara el metodo de resetear el contador
        return clearTimeout(logout);
      }
    });
  },
};
</script>
