import {
  ADMIN_ROLE,
  CLIENT_ROLE,
  CRUPIER_ROLE,
  OPERATOR_ROLE,
} from '../../helpers/CONSTANTS';

export const onlyAdmins = [ADMIN_ROLE, 'ADMIN_OPERATOR', 'PARTNER'];
export const onlyAdmin = [ADMIN_ROLE];
export const operatorPass = [OPERATOR_ROLE];
export const clientPass = [CLIENT_ROLE];
export const crupierPass = [CRUPIER_ROLE];
export const onlyAdminClient = [ADMIN_ROLE, CLIENT_ROLE];
export const onlyAdminOperator = [ADMIN_ROLE, OPERATOR_ROLE];
