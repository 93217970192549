import i18n from '../../i18n';

export const currenciesFields = [
  {
    key: 'name',
    sortable: true,
    label: i18n.t('currencies.name').toUpperCase(),
    class: 'text-center space-nowrap',
  },
  {
    key: 'short',
    sortable: true,
    label: i18n.t('currencies.abbreviation').toUpperCase(),
    class: 'text-center space-nowrap',
  },
  {
    key: 'symbol',
    sortable: true,
    label: i18n.t('currencies.symbol').toUpperCase(),
    class: 'text-center space-nowrap',
  },
  {
    key: 'usdExchange',
    sortable: true,
    label: i18n.t('currencies.exchangeValue').toUpperCase(),
    class: 'text-center space-nowrap',
  },
  // {
  //   key: "usdRateChange",
  //   sortable: true,
  //   label: i18n.t("currencies.usdRateChange").toUpperCase(),
  //   class: "text-center space-nowrap",
  // },
  {
    key: 'exchangeApi',
    sortable: true,
    label: i18n.t('currencies.automaticUpdate').toUpperCase(),
    class: 'text-center space-nowrap',
  },
  {
    key: 'status',
    sortable: true,
    label: 'DISPONIBLE',
    class: 'text-center space-nowrap',
    formatter: (item) => {
      return item ? 'ACTIVA' : 'INACTIVA';
    },
  },
  {
    key: 'updatedAt',
    sortable: true,
    label: i18n.t('currencies.lastUpdate').toUpperCase(),
    class: 'text-center space-nowrap',
  },
  {
    key: 'actions',
    sortable: false,
    label: i18n.t('actions.text').toUpperCase(),
    class: 'align-middle space-nowrap mx-4',
  },
];
