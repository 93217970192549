import i18n from '../../i18n';

export const state = {
  lang: 'es',
};

export const getters = {
  getLangSaved() {
    return state.lang;
  },
};

export const mutations = {
  setLang(state, payload) {
    state.lang = payload ? payload : 'es';
    localStorage.setItem('lang', payload);
    i18n.locale = state.lang;
  },
};

export const actions = {
  getLang({ commit }) {
    const lang = String(localStorage.getItem('lang'));
    commit('setLang', lang === 'null' ? 'es' : lang);
  },
};
